/* pickadate */
.picker--opened .picker__frame {
    @media (min-width: $sm) {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
}

input[type='text'].picker__input {
    background-image: url('/images/ico-calendar.png');
    background-position: right 15px center;
    background-repeat: no-repeat;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    &,
    &:hover,
    &:focus,
    &:active {
        background: $color-1;
        border-color: $color-1;
        box-shadow: none;
        color: #fff;
    }
}
.flatpickr-time .numInputWrapper input {
    padding: 9px;
    border: none;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
