.tabpanel {
    overflow: hidden;
    &-head {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        background: #E7E7E7;
    }
    &-tab {
        background: #E7E7E7;
        color: #000;
        cursor: pointer;
        display: inline-block;
        font-size: rem(12);
        font-weight: 400;
        padding: 10px;
        text-align: center;
        position: relative;
        @media (min-width: $sm) {
            padding: 10px 20px;
            font-size: rem(16);
        }
        @media (min-width: $md) {
            padding: 23px 40px;
        }

        &::before {
            background: lighten($color-1, 5%);
            bottom: 5px;
            content: '';
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 0;
            transition: 0.3s ease all;

            @media (min-width: $sm) {
                bottom: 5px;
            }
            @media (min-width: $md) {
                bottom: 16px;
            }
        }

        &.__active{
            background: white;
            box-shadow: 0 0 22px 0 rgba(0,0,0,0.12);
            color: $color-0;
            font-weight: 700;
            z-index: 10;

            &::before {
                width: calc(100% - 50px);
                @media (min-width: $md) {
                    width: calc(100% - 80px);
                }
            }
        }
    }
    &-content {
        box-shadow: 0 0 22px 0 rgba(0,0,0,0.12);
        position: relative;
    }
    &-panel {
        display: none;

        &.__active{
            display: block;
        }
    }
}
