/* ======= RESETS ========*/

/* reset */
* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

p {
    margin: 0;
}

strong {
    font-weight: $font-bold;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    border: none;
}

hr {
    border-top-color: #e5e5e5;
    clear: both;
}

form {
    margin: 0;
}

button {
    padding: 0;
    width: auto;
    border: none;
    background: none;
    font-weight: inherit;
    font-family: $font-family;
}

label {
    margin: 0;
    font-weight: inherit;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin: 0;
        padding: 0;
    }
}

a {
    display: inline-block;
    outline: none;
    color: inherit;
    text-decoration: none;
    transition: all 300ms;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
}

button {
    background: none;
    border: none;
    outline: none !important;
}

i {
    @extend %icon;
}
