.socialwrap {
    display: flex;
    margin: 30px 0;
}
.socialicon {
    font-size: rem(18);
    display: flex;
    align-items: center;
    margin: 0 30px 0 0;
    transition: all 0.3s;

    &::before {
        @extend %icon;
        font-size: 16px;
        margin: 0 5px 0 0;
        transition: all 0.3s;
    }
    &.__twitter {
        &::before {
            content: '\e958';
        }
    }
    &.__facebook {
        &::before {
            content: '\e951';
        }
    }
    &.__linkedin {
        &::before {
            content: '\e955';
        }
    }
    &.__instagram {
        &::before {
            content: '\e954';
        }
    }
    &.__pinterest {
        &::before {
            content: '\e956';
        }
    }
    &.__youtube {
        &::before {
            content: '\e900';
            font-size: rem(30);
        }
    }
    &:hover,
    &:focus {
        color: $color-0;

        &::before {
            color: $color-0;
        }
    }
}


.socialshare {
    align-items: center;
    display: inline-flex;
    margin: 30px 0;
    position: relative;

    &-title {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: rem(20);
        position: relative;
        text-transform: uppercase;

        button {
            align-items: center;
            background: $color-0;
            border-radius: 50%;
            cursor: pointer;
            display: inline-flex;
            font-size: 0;
            height: 40px;
            justify-content: center;
            margin: 0 0 0 15px;
            position: relative;
            transition: all 0.3s;
            width: 40px;

            &::after {
                @extend %icon;
                content: '\e942';
                color: #fff;
                font-size: 16px;
            }
        }
        &:hover,
        &:focus {
            button {
                background: darken($color-0, 10%);
            }
        }
    }
    &-socialset {
        display: none;

        &.__active {
            display: flex;
            background: #333;
            border-radius: 30px;
            padding: 5px 0;
            position: absolute;
            transition: all 0.3s;
            top: -60px;
            opacity: 1;
        }
    }
    .socialicon {
        align-items: center;
        background: $color-0;
        border-radius: 50%;
        display: inline-flex;
        font-size: 0;
        height: 40px;
        justify-content: center;
        margin: 0 5px;
        transition: all 0.3s;
        width: 40px;

        &::before {
            color: #fff;
            font-size: 16px;
            margin: 0;
        }
        &:hover,
        &:focus {
            background: darken($color-0, 5%);
        }
    }
}
