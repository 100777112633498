.table {
    width: 100%;
    border-collapse: collapse;

    &-wrapper {
        max-width: 100%;
        overflow: auto;
    }

    &-title {
        font-size: rem(22);
    }

    &.__small {
        thead,
        tbody {
            th, td {
                padding: 4px 6px;
            }
            th {
                font-size: 12px;
            }
        }
    }

    &.__padded {
        thead,
        tbody {
            th, td {
                padding: 18px;
            }
        }
    }

    &.__equalcols {
        table-layout: fixed;
        min-width: 900px;
    }

    &-gap {
        width: 28px;
        &::before {
            content: '';
        }
    }

    th, td {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            background: #D3D3D3;
        }

        &:first-child,
        &.table-gap,
        &.total {
            &::before {
                content: none;
            }
        }
    }

    thead {
        th {
            text-align: center;
            background: white;
            font-weight: 400;
            padding: 10px;

            &.total {
                background: #EDF7F9;
                font-weight: 500;
            }
        }
    }

    tbody {
        tr {
            th {
                text-align: left;
                font-weight: 400;
            }
            td {
                text-align: center;
                color: $color-1;

                &.__left {
                    text-align: left;
                }
                &.__right {
                    text-align: right;
                }
            }
            th,td {
                background: white;
                padding: 5px 10px;
                &.total {
                    background: #EDF7F9;
                }
            }
            &:nth-of-type(2n+1) {
                th, td {
                    background: #F3F3F3;
                    &.total {
                        background: #E2ECEE;
                    }
                }
            }
            &:last-child {
                td::before {
                    bottom: 15px;
                }
            }
        }
    }
}
