@function rem($pixels) {
    @return #{$pixels / 16}rem;
}

%clearfix {
    &::before,
    &::after {
        content: '';
        display: table;
    }
    &::after {
        clear: both;
    }
}

%icon {
    display: inline-block;
    text-transform: none;
    vertical-align: middle;
    font-family: icomoon;
    font-size: 22px;
    color: #000;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    transition: all 0.3s;
}

%overlay {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.4;
    transition: all 0.3s;
}

%bgcover {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sronly {
    position: absolute;
    left: -999999px;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    transition: none;

    &-focusable {
        border: 0;
        clip: rect(0,0,0,0);
        height: 1px;
        left: -999999px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        transition: none;
        width: 1px;

        &:focus {
            border: auto;
            clip: auto;
            height: auto;
            left: auto;
            margin: auto;
            overflow: auto;
            padding: auto;
            position: relative;
            width: auto;

            &.skiptomain {
                background: #fff;
                clip: auto;
                color: black;
                height: auto;
                left: 5px;
                margin: 0;
                position: absolute;
                top: 5px;
                width: auto;
                z-index: 1;
            }
        }
    }
}

%objectfit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

//reframe JS fallbacks
.js-reframe {
    padding-top: 56.25%;
    position: relative;
    width: 100%;
  }
  
  .js-reframe iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }