@import '../plugins/icomoon/style.css';

// Bootstrap loader
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_root';
@import '~bootstrap/scss/_grid';
@import '~bootstrap/scss/_utilities';

//Plugin includes
// @import '~slick-slider/slick/slick';
@import '~flatpickr/dist/flatpickr.min';
@import '~flatpickr/dist/themes/light';
// @import '~magnific-popup/dist/magnific-popup';
@import '~select2/dist/css/select2.min';

/* default */
$font-size: 16px;

/* title font */
$title-font: Roboto, sans-serif;
$title-color: #373C3F;
$title-regular: 400;
$title-medium: 500;
$title-bold: 700;

/* base font */
$font-family: Roboto, sans-serif;
$font-color: #373C3F;
$font-medium: 400;
$font-bold: 700;

/* devices */
$xs: 575px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

/* base colors */
$color-0: #373C3F;
$color-1: #3792B7;
$color-2: #5CB1C4;
$color-3: #FE6C6C;
$color-4: #FF9494;
$color-5: #F5D42C;
$color-6: #F8AA10;

/*call type colors - need to duplicate this info in app.blade for graphs*/
$callAlertsColor: #3792B7;
$escalationAlertsColor: #F8AA10;
$emergencyAlertsColor: #FE6C6C;
$assistanceAlertsColor: #9486D5;

/* form colors */
$form-error-bg: #ffcfcf;
$form-error: #ff3939;
$form-success: $color-1;
$form-text: #727272;

// found these hard coded colors in the code
$misc-1: #70C1CB;
$misc-2: #6EC0CA;
$misc-3: #0D759D;
$misc-4: #9BE5D9;
$misc-5: #6EC0CA;
$misc-6: #9BE5D9;
$misc-7: #3792B7;

/* ======= SOCIAL MEDIA ========*/
$facebook: #3b5998;
$twitter: #55acee;
$google: #dd4b39;
$youtube: #bb0000;
$linkedin: #007bb5;
$instagram: #125688;
$pinterest: #cb2027;
$flickr: #ff0084;
$tumblr: #32506d;
$vimeo: #aad450;
$foursquare: #0072b1;
$rss: #ff6600;


@import '../reset';
@import '../plugins';
@import '../helpers';
@import '../editor';
@import '../button';
@import '../form';
@import '../menus';
@import '../tabs';
@import '../tables';
@import '../charts';
@import '../paging';
@import '../socialshare';
@import '../print';

html {
    font-size: $font-size;
}

body {
    color: $font-color;
    font-family: $font-family;
    overflow-x: hidden;
    background-color: #F4F4F4;
    font-display: swap;

    &.__mobile {
        overflow: hidden;
    }
}

.container-fluid {
    &.no-gutters {
        padding-left: 0;
        padding-right: 0;
    }
}

.wrapper {
    position: relative;
    transition: all 0.3s;
    &.__searchactive {
        transform: translateY(60px);
    }
}

.page {
    &-wrapper {
        width: 2000px;
        margin: 0 auto;
        padding: 0 20px;
        max-width: 100%;
        min-height: 100vh;

        &.__small {
            width: 1200px;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
    }

    &-title {
        font-size: rem(18);

        @media (min-width: $md) {
            font-size: rem(30);
        }
    }
}

.header {
    background: #fff;
    box-shadow: 0 0 22px 0 rgba(0,0,0,0.12);

    @media (min-width: $break-point) {
        padding: 0 0 0 250px;
    }

    &-actions {
        display: flex;
        justify-content: flex-end;

        &::before,
        &::after {
            content: none;
        }

         > li {
             height: 100%;

             > * {
                 align-items: center;
                 display: flex;
                 height: 60px;
                 justify-content: center;
                 line-height: 1;
                 position: relative;

                 @media (min-width: $break-point) {
                     height: 80px;
                 }
             }
         }

        &-logout {
            background-image: linear-gradient(180deg, $misc-6 0%, $color-1 50%, $misc-6 100%);
            background-size: auto 200%;
            background-position: 0% 0%;

            color: #FFFFFF;
            cursor: pointer;
            font-family: $font-family;
            font-size: rem(10);
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            padding: 20px 15px;
            transition: 0.3s ease all;

            @media (min-width: $sm) {
                font-size: rem(16);
                padding: 30px 50px;
            }

            &:hover,
            &:focus,
            &:active {
                background-position: 0% 100%;
                color: #FFFFFF;
            }
        }

        &-settings {
            background: white;
            padding: 20px;
            font-size: 22px;
            cursor: pointer;
            width: 60px;

            @media (min-width: $md) {
                width: 80px;
                padding: 30px;
            }

            .icon {
                font-size: 22px;
                // left: 50%;
                // position: absolute;
                // top: 50%;
                // transform: translate(-50%, -50%);
                transition: 0.3s ease all;
                color: $color-1;
                background-image: -webkit-linear-gradient(right, $misc-6 0%, $color-1 100%);
                background-size: 100%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
            }

            &:hover,
            &:focus,
            &:active {
                .icon {
                    transform: scale(1.3);
                }
            }
        }

        &-name {
            padding: 20px 0 20px 30px;
            background-color: #F1F1F1;
            color: rgba($color-0, 0.8);
            font-size: rem(10);
            letter-spacing: 0;
            position: relative;
            z-index: 999999;

            @media (min-width: $sm) {
                font-size: rem(16);
                padding: 30px 20px 30px 50px;
            }

            .field_wrap {
                margin: 0;
                .label_wrap {
                    padding: 0;
                }
                .select2-css.select2-selection--single {
                    width: 200px;
                }
            }

            .icon {
                font-size: 16px;
                // left: 10px;
                // position: absolute;
                // top: 50%;
                // transform: translateY(-50%);
                color: $color-1;
                background-image: -webkit-linear-gradient(right, $misc-6 0%, $color-1 100%);
                background-size: 100%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;

                @media (min-width: $sm) {
                    font-size: 22px;
                    // left: 20px;
                }
            }
        }
    }

    .row {
        align-items: center;
    }

    &-logo {
        display: block;
    }

    &-opts {
        display: flex;
        justify-content: flex-start;
        @media (min-width: $break-point) {
            display: none;
        }
    }
}

.main {
    padding: 40px 0 0 0;
    display: block;
    @media (min-width: $break-point) {
        padding: 40px 0 0 250px;
    }
}

.login {
    background: white;
    &-sidebar {
        width: 100%;
        z-index: 1;
        position: relative;
        height: 300px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;

        @media (min-width: $md) {
            height: 100vh;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: cover;
            background-position: center;
            z-index: -1;

            @media (min-width: $md) {
                background-image: url('../../images/login-image.jpg');
            }
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(135deg, #82E3D8 0%, #2385AC 100%);
            background-size: cover;
            background-position: center;
            z-index: -1;
            opacity: 0.8;
        }

        &-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 15px;

            @media (min-width: $md) {
                padding: 220px 15px 15px 15px;
            }

            img {
                width: 200px;
                height: auto;
                max-width: 100%;

                @media (min-width: $md) {
                    width: 339px;
                }
            }
        }

        &-nav {
            padding-bottom: 15px;
            @media (min-width: $md){
                padding-bottom: 56px;
            }
            ul {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;

                > li.menu-item {
                    padding: 0 15px;

                    > a {
                        color: white;
                        font-size: rem(16);
                        font-weight: 500;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: -2px;
                            right: 0;
                            width: 0;
                            height: 1px;
                            background-color: #fff;
                            transition: 0.3s ease all;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            &::after {
                                width: 100%;
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    &-wrapper {
        width: 470px;
        margin: 0 auto;
        max-width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 100px 20px;
        height: 100%;

        @media (min-width: $md) {
            min-height: 100vh;
            padding: 0 20px;
        }
    }
    &-title {
        font-size: rem(26);
        margin-bottom: 50px;
        font-weight: 300;


        @media (min-width: $md) {
            font-size: rem(36);
        }
    }
    &-form {
        padding-bottom: 150px;
        max-width: 100%;

        @media (min-width: $md) {
            width: 350px;
        }
    }
}

.filterbar {
    position: relative;
    z-index: 9999;
    &.__dashboard {
        .filterbar-dates {
            opacity: 0;

            &.__show {
                opacity: 1;
            }
        }
    }
    &-title {
        font-size: rem(20);
        // width: 80px;
        flex: 0 0 auto;
        align-items: center;
        display: flex;
        margin-right: 25px;
    }
    &-alert {
        position: absolute;
        top: -30px;
        left: 15px;

        &-text {
            color: $form-success;

            .icon {
                font-size: 10px;
                margin-right: 5px;
            }
        }
    }
    &-filters {
        width: 100%;
    }
    .field_wrap,
    .input_wrap {
        margin: 0;
        width: 100%;
        display: block;

        input[type='date'],
        input[type='email'],
        input[type='file'],
        input[type='number'],
        input[type='password'],
        input[type='tel'],
        input[type='text'],
        input[type='time'],
        textarea,
        select,
        .select2-css.select2-selection--multiple,
        .select2-css.select2-selection--single {
            border: 1px solid #3994B8;
            background: white;
            width: 100%;

            &[disabled] {
                opacity: 0.4;
                border-color: $color-0;
                cursor: not-allowed;
            }
        }
    }
    &-dates {
        width: 100%;
        &-to {
            text-transform: uppercase;
            display: block;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: rem(12);
            font-weight: bold;
            justify-content: center;
        }
    }
}

.dropdownWindow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 999;
}
.dropdown {
    position: relative;
    &.__active {
        .dropdown {
            &-button {
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &-button {
        color: #444;
        background: #f0efef;
        border-radius: 3px;
        border: 1px solid transparent;
        box-sizing: border-box;
        font-family: $font-family;
        font-size: 1rem;
        height: auto;
        line-height: 1.2;
        margin: 0;
        min-width: calc(100% - 15px);
        outline: none;
        padding: 13px 15px 11px 15px;
        text-align: left;
        transition: all 200ms;
        width: 100%;
        position: relative;

        &::before {
            content: '';
            border-color: #888 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;
            height: 0;
            right: 15px;
            margin-left: -4px;
            margin-top: -2px;
            position: absolute;
            top: 50%;
            width: 0;
        }
    }

    &-content {
        border: 1px solid $color-1;
        border-radius: 3px;
        margin-top: 5px;
        padding: 11px 8px;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        position: absolute;
        z-index: 9999;
        background: white;
        min-width: 250px;
        max-height: 255px;
        overflow: auto;
    }

    .field_wrap.__checkbox > label,
    .field_wrap.__checkbox > .input_wrap > label {
        margin-bottom: 11px;
    }
}

.filterbar {
    .dropdown {
        padding-right: 5px;
        &-button {
            background: white;
            border: 1px solid #3994B8;
        }
    }
}

.alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-left: 4px solid $color-0;

    &.__red {
        border-color: red;
    }
    &.__orange {
        border-color: orange;
    }
    &.__green {
        border-color: limegreen;
    }
}

.__text {
    &-light {
        color: #6A7175;
    }
    &-small {
        font-size: rem(14);
    }
}

.divider {
    display: block;
    width: 100%;
    height: 1px;
    background: #F3F3F3;
    margin: 0 0 30px 0;
}

.card {
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.06);
    padding: 12px 15px;
    margin-bottom: 30px;
    width: 100%;
    height: calc(100% - 30px);

    @media (min-width: $sm) {
        padding: 12px 30px;
    }

    &.__nospacing {
        padding: 0;
    }
    &.__halfheight {
        height: calc(50% - 30px);
        height: calc(50% - 30px);

        display: flex;
        align-items: center;
        > .row {
            width: 100%;
        }
    }
    &.__center {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    &-header {
        background: linear-gradient(270deg, $misc-5 0%, $color-1 100%);
        color: #FFFFFF;
        font-size: rem(18);
        // height: 100%;
        width: 100%;
        padding: 10px 20px;

        @media (min-width: $md) {
            padding: 14px 26px;
        }

        @media (min-width: $lg) {
            font-size: rem(24);
        }

        .button {
            background: white;
            color: $color-1;

            &:hover,
            &:focus,
            &:active {
                background: lighten($color-2, 30%);
            }
        }

        &-highlight {
            height: 100%;
            padding: 2px 5px;
            background: rgba(255,255,255,0.2);

            @media (min-width: $md) {
                margin: -18px 26px -18px -26px;
                padding: 18px 26px;
            }
        }
    }
    &-content {
        padding: 21px 28px 33px 28px;

        &.px-0 {
            .paging {
                padding: 0 28px 20px 28px;

            }
        }
    }
}

a.card {
    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2);
    }
}

.avgaccept {
    &-stat {
        padding: 0 10px 0 0;
        border-right: 1px solid #D3D3D3;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-value {
            color: #56ADC2;
            font-size: rem(24);
            text-align: center;
        }
        &-label {
            color: #373C3F;
            text-align: center;
            font-size: rem(16);
        }
    }
    &-label {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 0 0 0 18px;
        flex-direction: column;
        &-heading {
            color: #373C3F;
            font-size: rem(20);
        }
    }
}

.report {
    &-list {
        &-item {
            background: white;
            box-shadow: 0 5px 10px 0 rgba(0,0,0,0.06);
            padding: 12px 15px 12px 24px;
            margin-bottom: 10px;
            width: 100%;
            height: calc(100% - 30px);
            border: 1px solid transparent;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: $sm) {
                padding: 12px 30px 12px 24px;
            }
            &::before {
                content: '';
                left: -1px;
                top: -1px;
                bottom: -1px;
                width: 4px;
                background: #D8D8D8;
                position: absolute;
                transition: 0.3s ease all;
            }

            &:hover,
            &:focus,
            &:active {
                box-shadow: 0 5px 20px 0 rgba(0,0,0,0.2);
                border-color: #77C7CD;
                border-left-color: transparent;

                &::before {
                    width: 10px;
                    background: $color-1;
                }

                .report-list-item-icon {
                    color: $color-1;
                }
                .report-list-item-view {
                    .icon {
                        color: $color-1;
                    }
                }
            }

            &-icon {
                padding: 0 20px 0 0;
                margin: 0 20px 0 0;
                position: relative;
                flex: 0 0 auto;
                color: #D8D8D8;
                display: flex;
                align-items: center;
                font-size: rem(20);

                .icon {
                    padding: 7px 0 5px 0;
                    transition: 0.3s ease all;
                }
                &::after {
                    content: '';
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    left: 100%;
                    position: absolute;
                    background: #D8D8D8;
                }
            }

            &-heading {
                color: $color-0;
                font-size: rem(18);
                display: flex;
                align-items: center;
                height: 100%;

                @media (min-width: $md) {
                    font-size: rem(20);
                }
            }
            &-view {
                align-items: center;
                color: $color-1;
                display: flex;
                flex: 0 0 auto;
                font-size: rem(16);
                font-weight: 500;
                height: 100%;
                text-align: right;

                @media (min-width: $sm) {
                    width: 120px;
                }

                .icon {
                    color: $misc-6;
                    margin-right: 0;
                    @media (min-width: $sm) {
                        margin-right: 10px;
                    }
                }

                &-text {
                    display: none;

                    @media (min-width: $sm) {
                        display: flex;
                    }
                }
            }
        }
    }
}

.list {
    &-numbered {
        li {
            background: white;
            counter-increment: item;
            padding: 14px 20px 14px 45px;
            position: relative;

            @media (min-width: $md) {
                padding: 14px 20px 14px 100px;
            }

            &:nth-of-type(2n+1) {
                background: #F3F3F3;
            }

            &::before {
                color: lighten($color-1, 1%);
                content: counter(item) ".";
                font-size: 16px;
                left: 2px;
                position: absolute;
                text-align: center;
                top: 15px;
                width: 40px;

                @media (min-width: $md) {
                    width: 60px;
                    left: 8px;
                }
            }

            &::after {
                left: 68px;
                height: calc(100% - 20px);
                top: 10px;
                width: 1px;
                background: #d3d3d3;
                position: absolute;

                @media (min-width: $md) {
                    content: '';
                }
            }
        }
    }
}

.settings {
    margin-bottom: 15px;
    &-heading {
        font-weight: 300;
        color: #6a7175;
        font-size: rem(16);
        margin-bottom: 5px;
    }
    &-description {
        color: $color-0;
        font-size: rem(16);
        font-weight: 400;
    }
}

.accordion {
    margin: -1px 0 20px 0;

    &-head {
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 4px;
        border: 1px solid #ddd;
        color: #333;
        cursor: pointer;
        display: flex;
        font-size: rem(18);
        justify-content: space-between;
        padding: 20px;
        padding-left: 42px;
        position: relative;
        transition: all 0.3s;
        width: 100%;

        &::after {
            @extend %icon;
            content: '\e95d';
            color: #000;
            font-size: 16px;
            left: inherit;
            right: 20px;
            transition: all 0.3s;
        }

        &.__active {
            background: #eaeaea;
            border-radius: 4px 4px 0 0;

            &::after {
                content: '\e960';
            }
        }

        &:hover,
        &:focus,
        &:active {
            background: #f9f9f9;
        }
    }
    &-content {
        padding: 20px;
        border: 1px solid #ddd;
        border-top: 0;
        display: none;
        border-radius: 0 0 4px 4px;
    }
}

.footer {
    background: $color-0;
    padding: 20px 0;
    text-align: center;
    color: #fff;

    @media (min-width: $break-point) {
        padding: 20px 0 20px 250px;
    }

    @media (min-width: $sm) {
        text-align: left;
    }

    &.__lower {
        background: darken($color-0, 10%);
    }

    &-logo {
    }

    &-nav {
        @media (min-width: $sm) {
            text-align: right;
        }

        ul {
            li {
                @media (min-width: $lg) {
                    display: inline-block;
                    margin: 0 0 0 20px;
                }

                a {
                    font-size: rem(14);
                    display: block;
                    color: #fff;
                    transition: all 0.3s;

                    &:hover,
                    &:focus {
                        color: $color-1;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.flexibleblocks {
    &-block {
        margin: 0 0 30px;
        @media (min-width: $lg) {
            margin: 0 0 80px;
        }
    }
}

// Remove transitions for users that have reduced motion system preferences
@media (prefers-reduced-motion: reduce) {
    * {
        &::before,
        &::after,
        &:active,
        &:hover,
        &:focus {
            transition: none !important;
        }
    }
}
