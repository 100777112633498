.d-print {
    display: none!important;
}
@media print {
    .d-print {
        display: block!important;
    }
    body {
        background-color: white!important;
    }
    .navwrap,
    .header,
    .card-header .button {
        display: none!important;
    }
    .main {
        padding: 0!important;
    }
    .card {
        box-shadow: none!important;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .card-header {
        background: white!important;
        color: black!important;
        border-bottom: 1px solid grey;
        padding-left: 0!important;
        padding-right: 0!important;
    }
    table {
        min-width: auto!important;
    }
    .table.__padded thead th,
    .table.__padded thead td,
    .table.__padded tbody th,
    .table.__padded tbody td {
        padding: 5px 10px;
    }
}

.pdf {
    background: white;
    color: black;

    /* headings */
    .h1 {
        font-size: rem(32);
    }

    .h2 {
        font-size: rem(26);
    }

    .h3 {
        font-size: rem(22);
    }

    .h4 {
        font-size: rem(18);
    }

    .h5 {
        font-size: rem(18);
    }

    .h6 {
        font-size: rem(16);
    }

    .subheading {
        color: grey;
    }

    .wrapper {
        transition: none;
    }

    .main {
         padding: 40px 0;
    }

    .card {
        box-shadow: none;
        margin-bottom: 0;
    }

    .table {
        border: 2px solid #D3D3D3;
        border-collapse: separate;
    }

    .table thead th {
        color: black;
        font-weight: 700;
        border-bottom: 2px solid #D3D3D3;
    }

    .table tbody tr td {
        color: black;
    }

    .table th::before,
    .table td::before {
        background: red;
        display: none;
    }
}
