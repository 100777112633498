/* form elements */
input[type='date'],
input[type='email'],
input[type='file'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='time'],
textarea,
select,
.select2-css.select2-selection--multiple,
.select2-css.select2-selection--single {
    background: #f0efef;
    border-radius: 3px;
    border: 1px solid transparent;
    box-sizing: border-box;
    font-family: $font-family;
    font-size: 1rem;
    height: auto;
    line-height: 1.2;
    margin: 0;
    outline: none;
    padding: 13px 15px 11px 15px;
    transition: all 200ms;
    width: 100%;
    min-width: calc(100% - 15px);
    &:focus {
        border-color: #333;
    }

    &[disabled] {
        opacity: 0.4;
        border-color: $color-0;
        cursor: not-allowed;
    }
}

form {
    width: 100%;
}

textarea {
    min-height: 180px;
}

input[type='file'] {
    padding: 10px;
    border: 1px dashed #bbb;
}

.field_wrap {
    position: relative;
    margin: 0 0 20px 0;
    border: 0;
    padding: 0;

    .label_wrap {
        padding-bottom: 6px;
        .label,
        label {
            font-weight: 300;
            color: #6a7175;
        }

        &.sronly {
            padding-bottom: 0;
        }
    }
}

.field_wrap.__search {
    position: relative;
    .input_wrap {
        input {
            background: white;
            border-radius: 100px;
            padding: 10px 15px;
            font-size: rem(14);
            color: $color-1;
            font-weight: bold;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid $color-1;
            }
        }
        input::-webkit-input-placeholder {
            color: $color-1;
            opacity: 1;
        }

        input:-moz-placeholder {
            color: $color-1;
            opacity: 1;
        }

        input::-moz-placeholder {
            color: $color-1;
            opacity: 1;
        }

        input:-ms-input-placeholder {
            color: $color-1;
            opacity: 1;
        }
    }
    .submit_wrap {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        .button {
            font-size: 0!important;
            background: none!important;
            padding: 10px;
            backface-visibility: hidden;
            &:hover,
            &:focus,
            &:active {
                transform: scale(1.2);
            }
            &::before {
                content: '\e941';
                font-family: 'icomoon';
                font-size: 14px!important;
                color: $color-1;
                font-weight: 400;
            }
        }

    }
}

.flatpickr-monthDropdown-months {
    min-width: auto;
}

/* datepickers */
.field_wrap.__datepicker {

    input {
        cursor: pointer;
    }
    &.__cal {
        .input_wrap::before {
            @extend %icon;
            content: '\e907';
            color: #5CB1C4;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            font-size: 16px;
        }
        input {
            padding-left: 42px;
            transition: 0.3s ease all;
        }
    }

    &.__arrow {
        .input_wrap::after {
            @extend %icon;
            content: '\e95d';
            color: #5CB1C4;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            font-size: 12px;
            pointer-events: none;
        }
        input {
            padding-right: 40px;
        }
    }
}

/* custom checkboxes + radio buttons */
.field_wrap.__checkbox {
    input[type='checkbox'] {
        position: absolute;
        left: -9999px;
        margin: 0;
    }
    > label,
    > .input_wrap > label {
        color: $form-text;
        cursor: pointer;
        display: block;
        margin: 0 0 15px 0;
        padding-left: 30px;
        position: relative;

        &::before {
            content: '';
            border: 1px solid #c7c7c7;
            background: #eaeaea;
            width: 20px;
            height: 20px;
            position: absolute;
            top: -1px;
            left: 0;
            color: transparent;
        }
        &::after {
            @extend %icon;
            content: '\e948';
            font-size: 12px;
            font-weight: 900;
            color: white;
            position: absolute;
            top: 3px;
            left: 4px;
            opacity: 0;
        }
        &.__checked {
            &::before {
                background: $form-success;
                border-color: $form-success;
            }
            &::after {
                opacity: 1;
            }
        }
        &.__focused {
            &::before {
                border-color: $form-success;
                background: darken(#eaeaea,5%);
            }
        }
        &.__focused.__checked {
            &::before {
                background: darken($form-success,5%);
                background: $form-success;
            }
        }
    }
}

.field_wrap.__radiobuttons {
    input[type='radio'] {
        position: absolute;
        left: -9999px;
        margin: 0;
    }
    > label,
    > .input_wrap > label {
        position: relative;
        display: block;
        margin: 0 0 15px 0;
        padding-left: 30px;
        cursor: pointer;
        color: $form-text;

        &::before {
            content: '';
            border: 1px solid #c7c7c7;
            border-radius: 50%;
            background: #eaeaea;
            width: 20px;
            height: 20px;
            position: absolute;
            top: -1px;
            left: 0;
            color: transparent;
        }
        &::after {
            content: '';
            border: 1px solid #c7c7c7;
            border-radius: 50%;
            background: $form-success;
            transform: scale(0.5);
            width: 20px;
            height: 20px;
            position: absolute;
            top: -1px;
            left: 0;
            color: transparent;
            opacity: 0;
        }
        &.__checked {
            &::before {
                border-color: #50be44;
                border-width: 2px;
            }
            &::after {
                opacity: 1;
            }
        }
        &.__focused {
            &::before {
                border-color: $form-success;
                background: darken(#eaeaea,5%);
            }
        }
    }
}

.__labelshrink {
    /* form elements */
    input[type='date'],
    input[type='email'],
    input[type='file'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    textarea,
    select,
    .select2-css.select2-selection--multiple,
    .select2-css.select2-selection--single {
        background-color: transparent;
        border-radius: 0;
        border: 0 solid #3994B8;
        border-bottom: 1px solid rgba(#979797, 0.4);
        box-sizing: border-box;
        font-family: $font-family;
        font-size: 1rem;
        height: auto;
        line-height: 1.2;
        margin: 0;
        outline: none;
        padding: 13px 0 11px 0;
        transition: all 200ms;
        width: 100%;
        &:focus {
            border-bottom: 1px solid $color-1;
        }

        &[disabled] {
            opacity: 0.4;
            border-color: $color-0;
            cursor: not-allowed;
        }
    }
    .field_wrap {
        position: relative;
        .label_wrap {
            backface-visibility: hidden;
            font-weight: 600;
            left: 0;
            padding-bottom: 0;
            pointer-events: none;
            position: absolute;
            top: 10px;
            transform-origin: top left;
            transform: scale(1);
            transition: all 0.3s ease;
            z-index: 1;

            > label {
                color: rgba(74,80,85,0.87);
                font-size: rem(16);
                font-weight: 300;
            }

            &.__focus {
                top: -10px;
                transform: scale(0.8);
            }
        }
        // stops issues from happening with checkbox and radiobuttons
        &.__checkbox,
        &.__radiobuttons {
            .label_wrap {
                position: relative;
                top: auto;
                left: auto;
                padding-bottom: 12px;

                label {
                    font-family: 'Roboto';
                    color: black;
                }

                &.__focus {
                    top: auto;

                    label {
                        font-size: rem(16);
                    }
                }
            }
        }
    }
}

/* placeholders */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: $form-text;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
    color: $form-text;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    color: $form-text;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: $form-text;
}

/* errors */

.input_error {
    background: $form-error-bg;
    border: 1px solid $form-error;
    border-top: none;
    color: #4b4243;
    padding: 15px 20px 14px 20px;
    display: flex;
    align-items: center;

    &::before {
        @extend %icon;
        content: '\e912';
        color: $form-error;
        font-size: 12px;
        margin-right: 12px;
    }
}

form {
    .label_wrap {
        label.error {
            color: $form-error;
        }
    }

    .input_wrap {
        input.error,
        select.error,
        textarea.error,
        &.error .select2-css {
            border: 1px solid $form-error;
            &.__inline {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

.field_wrap.__checkbox,
.field_wrap.__radiobuttons {
    > label,
    > .input_wrap > label {
        &.error {
            color: $form-error;

            &::before {
                background: $form-error-bg;
                border: 1px solid $form-error;
            }
            &::after {
                color: $form-error;
            }

            &.__checked {
                &::before {
                    background: $form-error;
                    border: 1px solid $form-error;
                }
                &::after {
                    color: white;
                }
            }
            &.__focused {
                &::before {
                    border-color: darken($form-error, 10%);
                    background: darken($form-error-bg, 5%);
                }
            }
        }
    }
}
.field_wrap.__radiobuttons {
    > label,
    > .input_wrap > label {
        &.error {
            &.__checked {
                &::after {
                    background: white;
                    border: 1px solid $form-error;
                }
            }
        }
    }
}

.error_feedback {
    margin: 0 0 2em 0;
    background: white;
    padding: 15px 20px;
    border: 1px solid $form-error;
    background: $form-error-bg;
    color: #4b4243;
    text-align: left;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 5px;
            display: flex;
            align-items: center;

            &::before {
                @extend %icon;
                content: '\e912';
                color: $form-error;
                font-size: 12px;
                margin-right: 12px;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}

// ~*~ Select2 ~*~ //
// Main select styles
.row.no-gutters {
    .__select {
        padding-right: 5px;
    }
}

.select2 {
    width: 100%!important;
}
.select2-css {
    &.select2-selection--multiple,
    &.select2-selection--single {
        .select2-selection__arrow {
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }

    // Styles for multiselect
    &.select2-selection--multiple {
        padding: 10px;
        .select2-selection__rendered {
            display: flex;
            align-items: center;
        }
        // Styles for single chosen options
        .select2-selection__choice {
            padding: 10px;
            margin: 0 10px 0 0;
        }
        .select2-search__field {
            height: 41px;
        }
    }
}

.select2-css.select2-selection--single {
    .select2-selection__rendered {
        padding-left: 0;
        line-height: 1.2;
    }
}

.select2-template {
    &-image {
        display: flex;
        align-items: center;

        img {
            margin: 0 10px 0 0;
        }
    }
}

// Styles for open and focus
.select2-container--focus,
.select2-container--open {
    .select2-css {
        &.select2-selection--multiple,
        &.select2-selection--single {
            border-color: $color-1;
        }
    }
    .select2-dropdown {
        border-color: $color-1;
    }
    .select2-dropdown--above,
    .select2-dropdown--below {
        border-radius: 3px;
    }
    .select2-dropdown--above {
        border-bottom: 1px solid $color-1;
        transform: translateY(-5px);
    }
    .select2-dropdown--below {
        border-top: 1px solid $color-1;
        transform: translateY(5px);
    }

}

// Styles for dropdown
.select2-results .select2-results__option {
    padding: 5px 8px;
    word-break: break-word;
    font-size: rem(14);

    // Styles for highlight
    &--highlighted[aria-selected] {
      background: #DBEAF2;
      color: $color-0;
    }
}

// Styles for search field
.select2-search--dropdown .select2-search__field {
    padding: 15px 20px;
}
.select2-container--default .select2-results > .select2-results__options {
    max-height: 255px;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 3px;
}
