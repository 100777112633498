.chart {
    width: 100%;
    height: 100%;

    &-legend {
        li {
            border-radius: 6px;
            margin: 0 10px;
            margin-bottom: 16px;
            padding: 10px 16px;
            background-color: #F3F3F3;

            @media (min-width: $sm) {
                margin-left: 0;
                margin-right: 0;
                background-color: transparent;
            }

            @media (min-width: $md) {
                background-color: #F3F3F3;
            }
        }
        &-stat {
            font-size: rem(26);
            text-align: center;
            &.__red {
                color: $color-3
            }
            &.__yellow {
                color: $color-6;
            }
            &.__blue {
                color: $color-1;
            }
            &.__other {
                color: #9486D5;
            }
            &.__callAlertsColor {
                color: $callAlertsColor
            }
            &.__escalationAlertsColor {
                color: $escalationAlertsColor
            }
            &.__emergencyAlertsColor {
                color: $emergencyAlertsColor
            }
            &.__assistanceAlertsColor {
                color: $assistanceAlertsColor
            }
        }
        &-label {
            font-size: rem(18);
            display: block;
        }
        &-link {
            color: $color-2;
            font-size: rem(14);
            font-weight: 500;
            transition: 0.3s ease all;

            &:hover,
            &:focus,
            &:active {
                transform: translateX(10px);

                &.__red {
                    color: $color-3
                }
                &.__yellow {
                    color: $color-6;
                }
                &.__blue {
                    color: $color-1;
                }
                &.__other {
                    color: #9486D5;
                }
                &.__callAlertsColor {
                    color: $callAlertsColor
                }
                &.__escalationAlertsColor {
                    color: $escalationAlertsColor
                }
                &.__emergencyAlertsColor {
                    color: $emergencyAlertsColor
                }
                &.__assistanceAlertsColor {
                    color: $assistanceAlertsColor
                }
            }
        }
    }

    &.__total-number-calls {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .chart-pie {
            max-width: 100%;
            position: relative;
        }
        .chart-overlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 65px;
            flex-direction: column;
        }

        .chart-stat {
            font-size: rem(32);
            margin-bottom: 10px;
            line-height: 1;

            &-description {
                font-size: rem(20);
                text-align: center;
                width: 100%;
            }
        }
    }
    &.__busyhours {
        margin: 0 -5px;
        position: relative;
        padding-top: 22px;
        padding-bottom: 10px;
        height: auto;

        .chart-overlay {
            justify-content: space-between;
            bottom: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 5px;
            position: absolute;
            right: 5px;
            top: 0;

            .busy-hours-top {
                border-bottom: 1px dashed #D3D3D3;
                text-transform: uppercase;
                width: 100%;
                display: block;
                padding-bottom: 10px;
                color: #989797;
                font-size: rem(12);
                top: 0;
            }
        }
    }

    &.__circle {
        position: relative;
        height: 100px;
        width: 100px;
        margin-right: 15px;
        flex: 0 0 auto;
        &::before {
            background: linear-gradient(to left, $misc-5 0%, $color-1 100%);
            border-radius: 200px;
            content: '';
            height: calc(100%);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100%);
            z-index: 0;
        }
        &::after {
            background: white;
            border-radius: 200px;
            content: '';
            height: calc(100% - 16px);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 16px);
            z-index: 0;
        }

        .chart-overlay {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            text-align: center;
        }
        .chart-stat {
            color: #56ADC2;
            font-size: rem(24);

            &-description {
                font-size: rem(16);
            }
        }

        &.__other {
            &::before {
                background: linear-gradient(to bottom, #A99BE5 0%, #9385D5 100%);
            }
            .chart-stat {
                color: #9385D5;
            }
        }
    }
}
