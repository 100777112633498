$break-point: 992px; // match the value in /js/main.js
$nav-width: 250px;

/* burger */
.burger {
    display: inline-block;
    width: 60px;
    height: 60px;
    font-size: 0;
    background: $color-0;
    color: #fff;
    // z-index: 9999;
    position: relative;
    cursor: pointer;
    transition: all 0.5s;
    flex: 0 0 auto;

    &.__active {
        // transform: translateX(-120px);
        .burger {
            &-toggle {
                background: transparent;
                &:before {
                    top: 0;
                    background: #fff;
                    transform: rotate(135deg);
                }
                &:after {
                    bottom: 0;
                    background: #fff;
                    transform: rotate(225deg);
                }
            }
        }
    }

    // @media (min-width: $break-point) {
    //     display: none;
    // }
    &-toggle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 3px;
        background: #fff;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            transform: translateZ(0);
            backface-visibility: hidden;
            transition: transform 0.3s;
        }
        &:before {
            top: -10px;
            transform: rotate(0);
        }
        &:after {
            bottom: -10px;
            transform: rotate(0);
        }
    }
}

.navwindow {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.8);
    z-index: 5555;
    display: none;
    cursor: pointer;
    transition: all 0.2s;
}

/* navwrap */
.navwrap {
    position: fixed;
    top: 0;
    left: -$nav-width;
    bottom: 0;
    height: 100%;
    width: $nav-width;
    z-index: 8888;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.18), 0 16px 16px 0 rgba(0,0,0,0.24);

    &.__active {
        left: 0 !important;
    }

    @media (min-width: $break-point) {
        z-index: 9999;
        left: 0 !important;
    }
}

/* nav */
.nav {
    height: 100%;
    position: relative;
    // float: left;
    width: 100%;
    overflow-y: auto;
    font-family: $title-font;
    background: $color-0;
    transition: all 0.3s;

    // @media (min-width: $break-point) {
    //     overflow-y: inherit;
    //     background: none;
    //     width: auto;
    //     opacity: 1;
    //     // display: block;
    // }

    ul {
        height: 100%;
        float: left;
        width: 100%;
        padding-bottom: 60px;

        // @media (min-width: $break-point) {
        //     display: flex;
        //     justify-content: flex-start;
        // }

        // menu item
        > li.menu-item {
            display: block;
            position: relative;

            // @media (min-width: $break-point) {
            //     display: inline-block;
            //     margin: 0 0 0 20px;
            //
            //     &:first-child {
            //         margin: 0;
            //     }
            // }

            &::before {
                content: '';
                background: #4D5357;
                bottom: 0;
                height: 1px;
                left: 20px;
                position: absolute;
                right: 14px;
            }

            .icon {
                font-size: 30px;
                right: calc(100% - 50px);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover > a,
            > a:focus {
                background: none;


                // @media (min-width: $break-point) {
                //     &::before {
                //         width: 100%;
                //         opacity: 1;
                //     }
                // }

                &::before {
                    left: 0;
                }
            }
            a {
                display: block;
                font-size: rem(18);
                color: #fff;
                padding: 20px 20px 20px 65px;
                position: relative;
                transition: all 0.3s;
                z-index: 0;

                &::before {
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to right, $color-1 0%, rgba($color-1, 0) 100%);
                    content: '';
                    top: 0;
                    left: -100%;
                    position: absolute;
                    transition: 0.3s ease all;
                    z-index: -1;
                }

                // @media (min-width: $break-point) {
                //     padding: 20px 0;
                //     height: 100%;
                //     border: none;
                //     color: $title-color;
                //
                //     &::before {
                //         content: '';
                //         height: 4px;
                //         width: 0;
                //         position: absolute;
                //         bottom: 0;
                //         left: 0;
                //         background: $color-1;
                //         transition: all 0.3s;
                //     }
                // }
            }
        }

        // has children
        > li.menu-item-has-children {
            position: relative;

            // @media (min-width: $break-point) {
            //     &:hover > ul,
            //     a:focus ~ ul,
            //     &.__active > ul {
            //         height: auto;
            //         overflow: visible;
            //     }
            // }

            > a {
                &::after {
                    content: '\e95f';
                    font-family: icomoon;
                    font-size: 10px;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translate(0, -50%);
                }
            }

            > ul {
                background: darken($color-0, 2%);
                position: fixed;
                width: $nav-width;
                top: 0;
                bottom: 0;
                left: -$nav-width;
                height: 100%;
                z-index: 50;
                transition: 0.3s ease all;

                &.__active {
                    overflow-y: auto;
                    left: 0 !important;
                    display: block;
                }

                // @media (min-width: $break-point) {
                //     position: absolute;
                //     width: $nav-width;
                //     top: inherit;
                //     bottom: inherit;
                //     right: inherit;
                //     height: inherit;
                //     z-index: 50;
                //     transform: translateX(0);
                //     display: block;
                //     height: 0;
                //     overflow: hidden;
                // }

                li {
                    @media (min-width: $break-point) {
                        display: block;
                        margin: 0;
                    }
                    &:hover > a,
                    > a:focus {
                        background: lighten($color-0, 5%);
                    }
                    > a {
                        color: #fff;
                        margin: 0;

                        // @media (min-width: $break-point) {
                        //     padding: 15px 20px;
                        //     line-height: inherit;
                        //
                        //     &::before {
                        //         display: none;
                        //     }
                        // }
                    }
                    ul {
                        background: darken($color-0, 4%);

                        &.__active {
                            overflow-y: auto;
                            right: 0 !important;
                            display: block;
                        }

                        // @media (min-width: $break-point) {
                        //     top: 0;
                        //     right: -$nav-width;
                        //     z-index: 60;
                        // }
                    }
                }
            }

        }

        // menu brand
        > li.menu-brand {
            padding: 12px 0;
            background: $color-1;
            color: white;
            background-image: linear-gradient(180deg, $misc-5 0%, $color-1 50%, $misc-5 100%);
            background-size: auto 200%;
            background-position: 0% 0%;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before,
            > a::before {
                content: none;
                content: none;
            }
            a {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
            }

            .menu-brand {
                &-image {
                    height: 125px;
                    width: 125px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &-text {
                    margin: 0 15px 0 -15px;
                }
            }
        }

        // log out
        > li.menu-logout {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;

            &::before {
                top: 0;
                bottom: auto;
            }
        }

        //comment out to show first-child icons
        // @media (min-width: $break-point) {
        //     &:first-child > li.menu-item-has-children > a {
        //         &::after {
        //             display: none;
        //         }
        //     }
        // }

        li.current-menu-item {
            background: #4D5357;

            .icon {
                color: $color-1;
                background-image: -webkit-linear-gradient(right, $misc-6 0%, $color-1 100%);
                background-size: 100%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
            }
            a {
                &::before {
                    background-image: linear-gradient(to right, lighten(#4D5357, 10%) 0%, rgba(lighten(#4D5357, 10%), 0) 100%);
                }
            }
        }

        //backto
        li.backto {
            position: relative;
            &::before {
                content: "";
                background: #4D5357;
                bottom: 0;
                height: 1px;
                left: 20px;
                position: absolute;
                right: 14px;
            }
            > a {
                font-size: rem(18);
                padding: 20px 20px 20px 65px;
                border-bottom: 1px solid #353434;
                position: relative;

                &::after {
                    @extend %icon;
                    content: '\e95e';
                    font-family: icomoon;
                    font-size: 10px;
                    color: #fff;
                    display: inline-block;
                    margin: 0 30px 0 0;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                }
            }

            &:hover > a,
            > a:focus {
                &::before {
                    left: 0;
                }
            }
        }
        //viewall
        li.viewall {
            position: relative;
            &::before {
                content: "";
                background: #4D5357;
                bottom: 0;
                height: 1px;
                left: 20px;
                position: absolute;
                right: 14px;
            }
            > a {
                font-size: rem(18);
                color: #fff;
                font-family: $title-font;
                padding: 20px 20px 20px 65px;

                .icon {
                    display: none;
                }
            }
            &:hover > a,
            > a:focus {
                &::before {
                    left: 0;
                }
            }
        }
    }
}

/* sidemenu */
.sidemenu {
    margin: 0 0 30px 0;

    &.__active {
        .sidemenu {
            &-toggle {
                &::after {
                    content: '\e960';
                }
            }
        }
    }

    &-toggle {
        font-size: rem(22);
        color: $title-color;
        border-bottom: 1px solid #ccc;
        text-transform: uppercase;
        padding: 0 0 10px 0;
        margin: 0 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: $lg) {
            display: none;
        }

        &::after {
            @extend %icon;
            content: '\e95d';
            font-size: 14px;
        }
    }
    &-menu {
        display: none;

        @media (min-width: $lg) {
            display: block !important;
        }

        ul {
            li.menu-item {
                &:hover > a,
                &:focus > a {
                    &::after {
                        width: 20px;
                        margin: 0 10px 0 0;
                    }
                }
                a {
                    color: $font-color;
                    font-size: rem(18);
                    padding: 5px 0;
                    display: flex;
                    position: relative;
                    align-items: center;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 0;
                        height: 2px;
                        background: $color-0;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: all 0.3s;
                    }
                }
            }
            li.current-menu-item > a {
                &::after {
                    width: 20px;
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}

.breadcrumb {
    background: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccc;

    &-content {
        padding: 15px 0;

        a,
        span {
            font-size: rem(18);
        }
        a {
            transition: all 0.3s;

            &:hover,
            &:focus {
                color: $color-0;
            }
        }
        i {
            font-size: 12px;
        }
    }
}
