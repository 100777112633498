.button {
    background-image: linear-gradient(180deg, $misc-6 0%, $color-1 50%, $misc-6 100%);
    background-position: 0% 0%;
    background-size: auto 200%;
    border-radius: 100px;
    border: none;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: rem(16);
    font-weight: bold;
    padding: 18px 40px;
    position: relative;
    text-align: center;
    text-align: center;
    text-decoration: none !important;
    transition: all 0.3s;

    &:hover,
    &:focus,
    &:active {
        color: white;
        background-color: transparent;
        background-position: 0% 100%;
    }

    &.__small {
        padding: 10px 15px;
        font-size: rem(14);
    }
    &.__danger {
        background-image: linear-gradient(180deg, $color-4 0%, $color-3 50%, $color-4 100%);
    }
    &.__dark {
        background-image: linear-gradient(180deg, $misc-1 0%, $misc-3 50%, $misc-1 100%);
    }

    &.__slidein {
        width: 100%;
        background: right top / 210% 100%
            linear-gradient(to right, darken($color-0, 10%) 50%, $color-0 50%);
        @media (min-width: $sm) {
            width: auto;
        }

        &:hover,
        &:focus {
            background: left top / 210% 100%
                linear-gradient(
                    to right,
                    darken($color-0, 10%) 50%,
                    $color-0 50%
                );
        }
    }
}

.button-checkbox {
    color: #727272;
    cursor: pointer;
    display: block;
    margin: 0 0 11px 0;
    padding-left: 30px;
    position: relative;
    font-size: rem(16);

    &::before {
        content: '';
        border: 1px solid #c7c7c7;
        background: #eaeaea;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -1px;
        left: 0;
        color: transparent;
    }
    &::after {
        @extend %icon;
        content: '\e948';
        font-size: 12px;
        font-weight: 900;
        color: white;
        position: absolute;
        top: 3px;
        left: 4px;
        opacity: 0;
    }
    &.__active {
        &::before {
            background: $form-success;
            border-color: $form-success;
        }
        &::after {
            opacity: 1;
        }
    }
    &:focus {
        &::before {
            border-color: $form-success;
            background: darken(#eaeaea,5%);
        }
    }
    &:focus.__active {
        &::before {
            background: darken($form-success,5%);
            background: $form-success;
        }
    }
}

.submit_wrap button,
.form-submit button {
    @extend .button;
}

.sliderbutton {
    @extend .button;
    width: 60px;
    height: 60px;
    padding: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &::after {
        @extend %icon;
        content: '\e95f';
        color: #fff;
        font-size: 28px;
    }

    &.__prev {
        &::after {
            content: '\e95e';
        }
    }
}
