.paging {
    padding: 0 0 20px 0;
    display: flex;
    font-size: rem(18);

    li {
        display: none;
        &.disabled {
            a,
            span {
                cursor: not-allowed;
                color: lighten($title-color, 30%);

            }
        }
        a,
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 0;
            margin: 0 0 0 0;
            background: #F3F3F3;
            color: $title-color;
            transition: all 0.3s;


            &:hover,
            &:focus {
                background: $color-1;
                color: #fff;
            }
        }
        &.active {
            a,
            span {
                background-color: $color-1;
                background-image: linear-gradient(180deg, $misc-6 0%, $misc-7 50%, $misc-6 100%);
                background-size: auto 200%;
                background-position: 0% 0%;
                color: #fff;

                &:hover,
                &:focus,
                &:active {
                    background-image: linear-gradient(180deg, $misc-6 0%, $misc-7 50%, $misc-6 100%);
                    background-color: none;
                    background-position: 0% 100%;
                }
            }
        }
        @for $i from 1 through 4 {

            &:first-child,
            &.active,
            &:last-child {
                display: flex;
            }

            @media (min-width: $lg) {
                &:nth-child(#{$i}),
                &:nth-last-child(-n+#{$i}),
                .page-separator {
                    display: flex;
                }
            }

        }
    }
    li.paging-prev {
        a,
        span {
            font-size: 0px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px 0 0 100px;
            &:before {
                font-family: 'icomoon';
                content: '\e95e';
                font-size: 14px;
            }
        }
    }
    li.paging-next {
        a,
        span {
            font-size: 0px;
            height: 100%;
            display: flex;
            align-items: center;
            border-radius: 0 100px 100px 0;
            justify-content: center;
            &:before {
                font-family: 'icomoon';
                content: '\e95f';
                font-size: 14px;
            }
        }
    }
}
