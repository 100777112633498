/* headings */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0 0 0.8125rem 0;
    line-height: 1;
}

.h1 {
    font-size: rem(32);
}

.h2 {
    font-size: rem(26);
}

.h3 {
    font-size: rem(22);
}

.h4 {
    font-size: rem(18);
}

.h5 {
    font-size: rem(18);
}

.h6 {
    font-size: rem(16);
}

.a {
    color: $color-1;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: darken($color-1, 15%);
        text-decoration: none;
    }
}

.a-alt {
    color: $color-2;
    font-weight: 500;
    transition: 0.3s ease all;

    &:hover,
    &:focus,
    &:active {
        transform: translateX(10px);

        &.__red {
            color: $color-3
        }
        &.__yellow {
            color: $color-6;
        }
        &.__blue {
            color: $color-1;
        }
        &.__other {
            color: #9486D5;
        }
    }
}

/* usercontent */
.usercontent,
.mce-content-body {
    h1 {
        @extend .h1;
    }

    h2 {
        @extend .h2;
    }

    h3 {
        @extend .h3;
    }

    h4 {
        @extend .h4;
    }

    h5 {
        @extend .h5;
    }

    h6 {
        @extend .h6;
    }

    p {
        margin: 0 0 2.5rem 0;
        line-height: 1.6em;
    }

    a {
        @extend .a;
    }

    form {
        a {
            color: #999;

            &:hover,
            &:focus {
                color: darken(#999, 15%);
            }
        }
    }

    ul,
    ol {
        margin: 0 0 2.5rem 0;
        padding: 0;
        list-style-type: none;
        line-height: 1.6em;
    }

    ul {
        li {
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 0 0 25px;

            &::before {
                content: '';
                color: $color-1;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $color-1;
                position: absolute;
                top: 7px;
                left: 0;
            }
        }
    }

    ol {
        li {
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 0 0 25px;
            counter-increment: step-counter;

            &::before {
                content: counter(step-counter);
                display: inline-block;
                width: 25px;
                color: $color-1;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    blockquote {
        word-break: normal;
        white-space: normal;
        background: none;
        font-size: rem(18);
        color: $font-color;
        margin: 1rem 2.5rem 2.5rem 2.5rem;

        &::before,
        &::after {
            @extend %icon;
            content: '\e93e';
            font-size: 22px;
            color: $color-1;
        }
        &::after {
            content: '\e93d';
        }

        > p {
            line-height: 1.6em;
            margin: 0 0 1rem 0;
        }

        footer {
            font-size: 0.9rem;
            padding-left: 20px;
        }
    }

    .iframe-wrap {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 100%;
        overflow: hidden;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .table-wrap {
        display: block;
        width: 100%;
        overflow-x: auto;
        table {
        	width: 100%;
        	max-width: 100%;
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    // wp wysiwyg styles
    .alignleft {
        float: left;
    }

    .alignright {
        float: right;
    }

    table {
        border-collapse: collapse;

        tr {
            td, th {
                border: 1px solid #ccc;
                padding: 10px;
                text-align: left;
            }

            th{
                background: #e7e7e7;
            }
        }
    }
}
